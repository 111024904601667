import React from 'react';
class ImageQualityManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deviceSize: "",
        }
        this.setDeviceSize = this.setDeviceSize.bind(this);
    }
    setDeviceSize = () => {
        if(window.screen.availWidth > 1920){
            this.setState({deviceSize:"4k"});
          };
    
          if(window.screen.availWidth > 1024){
            this.setState({deviceSize:"hd"});
          };
    
          if(window.screen.availWidth <= 1024 && window.screen.availWidth >= 500){
            this.setState({deviceSize:"tablet"});
          };
    
          if(window.screen.availWidth < 500){
            this.setState({deviceSize:"mobile"});
          };
      }
    
      componentDidMount() {
           this.setDeviceSize();
      }
    render() {
        if(this.props.name === 'main') {
          return(<div className={"image-landing main-" + this.state.deviceSize}></div>);
        }
        else if (this.props.name === 'contact') {
          return(<div className={"image-contact contact-" + this.state.deviceSize}></div>);
        } else {
          return(<img className={this.props.className} alt="" onClick={() => this.props.callBackAction()} src={"/" + this.props.name + "_" + this.state.deviceSize + '.' + this.props.extension}/>);
        }
    }
}

export default ImageQualityManager;
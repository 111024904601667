import React from 'react';
import Preview from './../Preview/Preview';
import ImageQualityManager from './../ImageQualityManager/ImageQualityManager';
class PortfolioItem extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isPreviewOpened: false,
            previewData: {},
        }
        this.openPreview = this.openPreview.bind(this);
        this.closePreview = this.closePreview.bind(this);
    }

    openPreview = (data) => {
        this.setState({isPreviewOpened: true});
        //,previewData: {name: data.name, url: data.url}}
      }
    
      closePreview = () => {
        this.setState({isPreviewOpened: false});
      }
    render() {
        let preview = '';
        if (this.state.isPreviewOpened) {
            preview = <Preview data={this.props.data} isPreviewOpened={this.state.isPreviewOpened} callBackAction={this.closePreview}></Preview>;
        }
        let type = '';
        if (this.props.type === 'laptop') {
            type = 'flanaganbikes';
        } else if (this.props.type === 'tablet') {
            type = "veniture";
        } else if (this.props.type === 'phone') {
            type = "nucaffe";
        } else if (this.props.type === 'desktop') {
            type = "vst";
        }
        return(
            <div className="portfolio-container">
            <div className="portfolio-item">
            <div className="details">
                    <div className="number">{this.props.number}</div>
                    <div className="title">{this.props.text}</div>
            </div>
                <ImageQualityManager name={type} className={type} extension="png" callBackAction={this.openPreview} />
                {preview}
            </div>
            </div>
        );
    }
}

export default PortfolioItem;
import './App.scss';
import react from 'react';
import PortfolioItem from './PortfolioItem/PortfolioItem';
import ImageQualityManager from './ImageQualityManager/ImageQualityManager';
class App extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
      portfolioMouseOver:false,
    }
    this.scrollToView = this.scrollToView.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.scrollPortfolio = this.scrollPortfolio.bind(this);
    this.sideScroll = this.sideScroll.bind(this);
    this.observeEntryOnPortfolio = this.observeEntryOnPortfolio.bind(this);

  }
  scrollToView = (id) => {
    let anchor = document.querySelector("#" + id);
    anchor.scrollIntoView();
    this.setState({isMenuOpened: false});
  }

  openMenu = () => {
    this.setState({isMenuOpened: !this.state.isMenuOpened});
  }
  
  observeEntryOnPortfolio = () => {
    const sectionOne = document.querySelector('.portfolio');
    const options = {
      root: null, 
      threshold: 0,
      rootMargin: "-50%",
    };
    let callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting === true) {
          this.setState({portfolioMouseOver: true});
        } else {
          this.setState({portfolioMouseOver: false});
        }
      });
    }
    const observer = new IntersectionObserver(callback,options);
    observer.observe(sectionOne);
  };

  scrollPortfolio = (direction) => {
    if (direction === 'left') {
      //document.getElementById('work').scrollLeft += document.getElementById('work').offsetWidth / 2;
      let container = document.getElementById('work');
      this.sideScroll(container,'left',2,document.getElementById('work').offsetWidth / 2,10);
    }

    if (direction === 'right') {
      let container = document.getElementById('work');
      this.sideScroll(container,'right',2,document.getElementById('work').offsetWidth / 2,10);
      //document.getElementById('work').scrollLeft -= document.getElementById('work').offsetWidth / 2;
    }
  }

  sideScroll = (element,direction,speed,distance,step) => {
   let scrollAmount = 0;
    let slideTimer = setInterval(function(){
        if(direction === 'left'){
            element.scrollLeft -= step;
        } else {
            element.scrollLeft += step;
        }
        scrollAmount += step;
        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
} 

componentDidMount() {
  this.observeEntryOnPortfolio();
}
// <div className="image-landing"></div>
  render() {
    return (
      <div className="App">
        <div className="background">
        </div>
        <div className="header">
          <a href="#home"><img src="logo.svg" className="logo" alt=""></img></a>
          <div className={ this.state.isMenuOpened ? "icon-mobmenu icon-close" : "icon-mobmenu"} onClick={() => this.openMenu()}></div>
          <div className={ this.state.isMenuOpened ? "menu" : "menu hidden"}>
            <a href="#home" onClick={() => this.scrollToView('home')}>HOME</a>
            <a href="#work" onClick={() => this.scrollToView('work')}>WORK SAMPLES</a>
            <a href="#contact" onClick={() => this.scrollToView('contact')}>CONTACT</a>
          </div>
        </div>
      <div className="foreground">
        <div id="home" className="home">
          <div className="headliners">
            <h1>CREATIVE STUDIO BASED IN SOPOT</h1>
            <h2>My work moves around <strong>UI/UX, websites, front-end apps and visual identity</strong></h2>
          </div>
          <ImageQualityManager name={'main'} className="image-landing" extension="png" callBackAction={this.openPreview} />
        </div>
        <div className="relative"><div className="headliner">WORK SAMPLES</div></div>
        { this.state.portfolioMouseOver === true ?
                <div className="portfolio-navigation">
                <div className="title">SLIDE HERE</div>
                <div className="right" onClick={() => this.scrollPortfolio('right')} />
                <div className="left" onClick={() => this.scrollPortfolio('left')} />
              </div>
        : ''
      }

        <div id="work" className="portfolio">
          <PortfolioItem type="laptop" number="1" text="Flanagan Bikes Shop" data={{name: 'Flanagan Bikes Shop', url: 'works/bike_shop.jpg'}}></PortfolioItem>
          <PortfolioItem type="tablet" number="2" text="Veniture furniture shop" data={{name: 'Veniture furniture shop', url: 'works/chair_shop.jpg'}}></PortfolioItem>
          <PortfolioItem type="phone"  number="3" text="nuCaffee cms shop" data={{name: 'Flanagan Bikes Shop', url: 'works/coffee_shop.jpg'}}></PortfolioItem>
          <PortfolioItem type="desktop" number="4" text="Space Piano VST & Ethernal EQ" data={{name: 'VST Plugins', url: 'works/vst_plugins.jpg'}}></PortfolioItem>
          </div>
        <div id="contact" className="contact">
          <div className="headliner">LET'S TALK</div>
          <div className="container">
            <div className="col">
              <ImageQualityManager name={'contact'} className="image-landing" extension="png" callBackAction={this.openPreview} />
            </div>
            <div className="col">
              <div className="image-designer"></div>
              <div className="name">Tomasz Jagoda</div>
              <div className="position">Web Developer & Visual Designer</div>
              <a href="mailto:contact@mellowsketches.com" className="btn-link"><div className="btn">Contact Me</div></a>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="copyright">
            <p>Copyright <strong>2021</strong></p>
          </div>
          <div className="address">Company located at <strong>Sopot, Poland</strong></div>
          <div className="mail">
            <p>contact@mellowsketches.com</p>
          </div>
        </div>
        </div>
        <div className="icon-container">
          <a href="https://dribbble.com/mellowsketches" target="_blank"><div className="icon icon-dribbble"/></a>
          <a href="https://pl.pinterest.com/mellowsketches/" target="_blank"><div className="icon icon-pinterest"/></a>
          <a href="https://www.behance.net/mellowsketches" target="_blank"><div className="icon icon-behance"/></a>
        </div>
      </div>
    );
  }
}

export default App;